<template>
  <div>
    <div class="holder">
      <div class="dashboard_holder">
        <SmallNav />
        <div class="properties_count">
          <div>
            <img
              @click="goBack"
              src="../assets/images/thickbackarrow.svg"
              alt="back"
            />
            <h6>
              Propertie(s)
              <span v-if="properties">({{ properties.length }})</span>
            </h6>
          </div>
          <div class="add_prop_btn">
            <button class="btn" @click="moveToAddProp()">Add Property +</button>
          </div>
        </div>
        <div class="empty__state" v-if="properties.length === 0 && !hideDiv">
          <div class="empty__div">
            <img src="../assets/images/empty-state.svg" alt="" />
            <p>No property to view at the moment</p>
          </div>
        </div>
        <div v-if="hideDiv">
          <PageLoader></PageLoader>
        </div>
        <div v-else class="container wrap_row">
          <div class="row">
            <div
              class="col-md-6 col-lg-4 col-sm-12 col-lg-4 col-xl-4"
              v-for="(item, index) in properties"
              :key="index"
            >
              <div class="for_guarentee_rent">
                <p>We can Guarantee this rent!</p>
                <p>Get more from this property</p>
              </div>

              <div class="grid_wrap">
                <div class="days_left">
                  <h3>0</h3>
                  <h6>Days Left</h6>
                </div>
                <div class="menu_bar">
                  <!-- <span class="menu_text">Menu</span> -->
                  <div class="btn-group">
                    <button
                      class="btn dropdown-toggle btn_menu"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div class="dot_menu_holder">
                        <span>.</span><span>.</span><span>.</span>
                      </div>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                      <button
                        disabled
                        @click="navigateTo(1, item)"
                        class="dropdown-item"
                        type="button"
                      >
                        Facilities
                      </button>
                      <hr />
                      <button
                        disabled
                        @click="navigateTo(2)"
                        class="dropdown-item"
                        type="button"
                      >
                        Add/View Property Manager
                      </button>
                      <hr />
                      <button
                        disabled
                        @click="navigateTo(3, item)"
                        class="dropdown-item"
                        type="button"
                      >
                        Add/View Tenant
                      </button>
                      <hr />
                      <button
                        @click="navigateTo(4, item)"
                        class="dropdown-item"
                        type="button"
                      >
                        Plans to subscribe
                      </button>
                      <hr />
                      <!-- :disabled="rent.verified === 'NO'" -->

                      <button
                        @click="navigateTo(5, item)"
                        class="dropdown-item"
                        type="button"
                        disabled
                      >
                        Rent Value
                      </button>
                      <hr />
                      <button
                        v-if="item.approved != '1'"
                        @click="navigateTo(6, item)"
                        class="dropdown-item"
                        type="button"
                      >
                        Edit Property
                      </button>
                      <hr />
                      <button
                       v-if="item.approved != '1'"
                        @click="navigateTo(7, item)"
                        class="dropdown-item"
                        type="button"
                        data-toggle="modal"
                        data-target="#deletePropertyConfirm"
                      >
                        Delete Property
                      </button>
                      <!-- <button
                        class="dropdown-item"
                        type="button"
                        data-toggle="modal"
                        data-target="#check"
                      >
                        Modal click
                      </button> -->
                    </div>
                  </div>
                </div>
                <!-- :class="[
                    rent.rentfinance === 'YES' ? 'hasRentFinancing' : '',
                  ]" -->
                <div style="height: 216px; max-height: 100%;">
                  <img
                    v-if="item.oneimageurl"
                    class="cover_img"
                    :src="item.oneimageurl"
                    alt="property-img"
                  />
                  <img
                    v-else
                    class="cover_img"
                    src="../assets/images/empty-prop-blue.svg"
                    alt="property-img"
                  />
                </div>
                <div class="base">
                  <div class="title_wrap">
                    <h4>{{ item.title }}</h4>
                    <p v-if="item.state">{{ item.state }}, {{ item.city }}</p>
                  </div>
                  <div class="price_holder">
                    <div class="selected_price_holder">
                      <div>
                        <!-- <span class="check_main">
                          <span class="check"></span>
                        </span> -->
                        <!-- <div v-if="rent.approved == 'NO'"></div> -->
                        <div class="real_amount">
                          <img
                            v-if="
                              item.packageid != '1' &&
                                item.packagestring != 'Basic'
                            "
                            src="../assets/images/g-tag.svg"
                            alt="guarenty"
                          />
                          <h5 class="selected_amount">
                            &#8358;{{ Number(item.price).toLocaleString() }}
                          </h5>
                          <p>Annually</p>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="per_anuum">
                      <h5>
                        &#8358;{{
                          Number(
                            rent.paynowvalue * rent.tenantmodeofpaymentsId
                          ).toLocaleString()
                        }}
                      </h5>
                      <p>Per Anuum</p>
                    </div> -->
                  </div>
                  <div class="four_button">
                    <button class="btn">
                      <div>
                        <img
                          v-if="
                            item.packageid === '1' &&
                              item.packagestring === 'Basic'
                          "
                          src="../assets/images/green-check-rounded.svg"
                          alt="ckeck"
                        />
                        <p v-else>0%</p>
                      </div>
                      Basic
                    </button>
                    <button
                      class="btn"
                      data-toggle="modal"
                      data-target="#plans"
                      @click.prevent="
                        planType(2);
                        planid = item.packageid;
                        planname = item.packagestring;
                        propertyId = item.id;
                      "
                    >
                      <div>
                        <img
                          v-if="
                            item.packageid === '2' &&
                              item.packagestring === 'Standard'
                          "
                          src="../assets/images/green-check-rounded.svg"
                          alt="ckeck"
                        />
                        <p v-else>6%</p>
                      </div>
                      Standard
                    </button>
                    <button
                      class="btn"
                      data-toggle="modal"
                      data-target="#plans"
                      @click.prevent="
                        planType(3);
                        planid = item.packageid;
                        planname = item.packagestring;
                        propertyId = item.id;
                      "
                    >
                      <div>
                        <img
                          v-if="
                            item.packageid === '3' &&
                              item.packagestring === 'Classic'
                          "
                          src="../assets/images/green-check-rounded.svg"
                          alt="ckeck"
                        />
                        <p v-else>10%</p>
                      </div>
                      Classic
                    </button>
                    <button
                      class="btn"
                      data-toggle="modal"
                      data-target="#plans"
                      @click.prevent="
                        planType(4);
                        planid = item.packageid;
                        planname = item.packagestring;
                        propertyId = item.id;
                      "
                    >
                      <div>
                        <img
                          v-if="
                            item.packageid === '4' &&
                              item.packagestring === 'Premium'
                          "
                          src="../assets/images/green-check-rounded.svg"
                          alt="ckeck"
                        />
                        <p v-else>15%</p>
                      </div>
                      Premium
                    </button>
                  </div>
                  <div class="more_details">
                    <h6>MORE DETAILS</h6>
                    <div>
                      <td v-if="item.status === 'Vacant'">
                        <div class="green-dot"></div>
                      </td>
                      <td v-if="item.status === 'Locked'">
                        <div class="red-dot"></div>
                      </td>
                      <td v-if="item.status === 'Occupied'">
                        <div class="yellow-dot"></div>
                      </td>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Delete Property modal starts -->
        <div class="modal" tabindex="-1" id="deletePropertyConfirm">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header title_modal">
                <h5 class="modal-title">Delete Property</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="confrim-delete-text">
                  Are you sure you want to delete this property?
                </p>
              </div>
              <div class="confirm-delete">
                <button
                  class="btn cancel"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn delete" @click="deleteProperty">
                  Delete
                  <i
                    class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                    v-if="deleteLoader"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Delete Property modal ends -->

        <!-- Property plan modal data begin-->
        <div class="modal" tabindex="-1" id="plans">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header title_modal">
                <h5 class="modal-title">Property Management Plan</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body plan_body">
                <div class="standard_pan" v-if="plantype === 2">
                  <div class="plan_header standard_plan">
                    <h3>STANDARD PLAN</h3>
                  </div>
                  <div class="plan_description">
                    <p>
                      The Shelta Standard Plan is designed for property owners
                      with independent property managers.
                    </p>
                  </div>
                  <div class="benefits">
                    <p>Benefits:</p>
                    <ul>
                      <li>Tenant Management</li>
                      <li>Guaranteed Rent</li>
                      <li>
                        Property Management Dashboard to ease remote management
                      </li>
                      <li>Access to verified Vendors</li>
                    </ul>
                    <p>Shelta charges 6% of rent value Annually.</p>
                  </div>
                </div>
                <div class="standard_pan" v-if="plantype === 3">
                  <div class="plan_header classic_plan">
                    <h3>CLASSIC PLAN</h3>
                  </div>
                  <div class="plan_description">
                    <p>
                      The Shelta Classic Plan is designed for property owners
                      with independent property managers.
                    </p>
                  </div>
                  <div class="benefits">
                    <p>Benefits:</p>
                    <ul>
                      <li>Tenant Management</li>
                      <li>Guaranteed Rent</li>
                      <li>
                        Property Management Dashboard to ease remote management
                      </li>
                      <li>Access to verified Vendors</li>
                    </ul>
                    <p>Shelta charges 10% of rent value Annually.</p>
                  </div>
                </div>
                <div class="standard_pan" v-if="plantype === 4">
                  <div class="plan_header premium_plan">
                    <h3>PREMIUM PLAN</h3>
                  </div>
                  <div class="plan_description">
                    <p>
                      The Shelta Premium Plan is designed for property owners
                      with independent property managers.
                    </p>
                  </div>
                  <div class="benefits">
                    <p>Benefits:</p>
                    <ul>
                      <li>Tenant Management</li>
                      <li>Guaranteed Rent</li>
                      <li>
                        Property Management Dashboard to ease remote management
                      </li>
                      <li>Access to Verified Vendors</li>
                      <li>Litigation Cover</li>
                    </ul>
                    <p>Shelta charges 15% of rent value Annually.</p>
                  </div>
                </div>
              </div>
              <div class="plan_footer_btn" v-if="!sub">
                <div
                  class="subscribe_text_btn standard_plan"
                  v-if="plantype === 2"
                >
                  <h3 @click="clickedSubscribe()">Click here to subscribe</h3>
                </div>
                <div
                  class="subscribe_text_btn classic_plan"
                  v-if="plantype === 3"
                >
                  <h3 @click="clickedSubscribe()">Click here to subscribe</h3>
                </div>
                <div
                  class="subscribe_text_btn premium_plan"
                  v-if="plantype === 4"
                >
                  <h3 @click="clickedSubscribe()">Click here to subscribe</h3>
                </div>
                <div class="four_button four_btn_modal">
                  <button class="btn">
                    <div>
                      <img
                        v-if="planid === '1' && planname === 'Basic'"
                        src="../assets/images/green-check-rounded.svg"
                        alt="ckeck"
                      />
                      <p v-else>0%</p>
                    </div>
                    Basic
                  </button>
                  <button class="btn" @click.prevent="planType(2)">
                    <div>
                      <img
                        v-if="planid === '2' && planname === 'Standard'"
                        src="../assets/images/green-check-rounded.svg"
                        alt="ckeck"
                      />
                      <p v-else>6%</p>
                    </div>
                    Standard
                  </button>
                  <button class="btn" @click.prevent="planType(3)">
                    <div>
                      <img
                        v-if="planid === '3' && planname === 'Premium'"
                        src="../assets/images/green-check-rounded.svg"
                        alt="ckeck"
                      />
                      <p v-else>10%</p>
                    </div>
                    Classic
                  </button>
                  <button class="btn" @click.prevent="planType(4)">
                    <div>
                      <p>15%</p>
                    </div>
                    Premium
                  </button>
                </div>
              </div>
              <div class="termsOfService" v-if="sub">
                <h2 class="d-none">See Terms of Service</h2>
                <h6 class="d-none">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  pharetra, est vel pulvinar auctor, magna purus pellentesque
                  enim, at sagittis mi lectus quis libero.
                </h6>
                <p class="d-none">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  pharetra, est vel pulvinar auctor, magna purus pellentesque
                  enim, at sagittis mi lectus quis libero. Quisque eu
                  scelerisque eros, non accumsan quam. Morbi sit amet tristique
                  enim. Nunc vel venenatis velit. Aenean aliquet ultrices magna
                  maximus vulputate. Nullam finibus viverra quam, quis fringilla
                  nibh pulvinar vulputate. Quisque interdum arcu accumsan lectus
                  laoreet dignissim. Praesent sed mauris lacus. Sed at luctus
                  nisi. Donec sollicitudin varius viverra. Curabitur tempor
                  ligula porttitor, dictum urna sed, tincidunt urna. Integer
                  vitae finibus dui.
                </p>
                <div class="agree_btn">
                  <button class="btn" @click="cancelSubscribeTab()">
                    Cancel
                  </button>
                  <button class="btn" @click="choosePlan()">
                    I Agree
                    <i
                      class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                      v-if="loader"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Property plan modal data end -->

        <!-- plans modal for landlord begins-->
        <div class="modal" tabindex="-1" id="plansSubscription">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header title_modal">
                <h5 class="modal-title">Different Plans</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body plan_body">
                <div>
                  <p class="plans_header_description">
                    These Plans were carefully designed to put your property
                    management burden behind you. <br />
                    You can bank on us. <span> We got you covered.</span>
                    We want you to live while living. All plans are designed for
                    approved rental values
                  </p>
                </div>
                <div class="row">
                  <div class="col-md-8 col-lg-8 col-sm-12">
                    <div class="row">
                      <div class="col-md-6 mb-4">
                        <div class="basic_box">
                          <small>0%</small>
                          <p>Basic</p>
                        </div>
                      </div>
                      <div class="col-md-6 mb-4">
                        <div class="standard_box" @click="plansNavigation(2)">
                          <small>6%</small>
                          <p>Standard</p>
                        </div>
                      </div>
                      <div class="col-md-6 mb-4">
                        <div class="classic_box" @click="plansNavigation(3)">
                          <small>10%</small>
                          <p>Classic</p>
                        </div>
                      </div>
                      <div class="col-md-6 mb-4">
                        <div class="premium_box" @click="plansNavigation(4)">
                          <small>15%</small>
                          <p>Premium</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 mb-4">
                    <div class="special_box">
                      <p>PM Special</p>
                    </div>
                  </div>
                </div>

                <div class="plans-details_footer">
                  <h4>We want you to WIN While living</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- plans modal for landlord end -->

        <!-- Rent finance check modal data begin-->
        <div class="modal" tabindex="-1" id="cantpay">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Information:</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body notverified_body">
                Property Not Approved
              </div>
            </div>
          </div>
        </div>
        <!-- Rent finance check modal data end -->
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import PageLoader from "./PageLoader.vue";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";

export default {
  name: "LandlordProperties",
  components: {
    SmallNav,
    PageLoader
  },
  data() {
    return {
      user: "",
      id: "",
      loader: false,
      hideDiv: false,
      error: false,
      sub: false,
      deleteLoader: false,
      userid: "",
      properties: "",
      planid: "",
      planId: "",
      planname: "",
      propertyId: "",
      // percentageFull: false,
      // payableAmount: "",
      // hasLandlord: "",
      // hasAccountDetails: "",
      // dataDetails: "",
      // propPin: "",
      plantype: ""
    };
  },
  mounted() {
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.getUserProfile();
    this.fetchLandlordProperties();
    // this.getPackage();
    // this.moreTenantData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    clickedSubscribe() {
      this.sub = true;
    },
    cancelSubscribeTab() {
      this.sub = false;
    },
    planType(event) {
      // console.log('plan type', this.plantype, 'Button clicked', event);
      this.plantype = event;
      this.planId = event;
    },
    priceToPay(data) {
      this.payableAmount = data;
    },
    switchPaymentPlan(id) {
      if (id) {
        this.$router
          .push({
            path: `/dashboard/switch/${id}`
          })
          .catch(() => {});
      }
    },
    moveToAddProp() {
      this.$router.push({
        path: `/addproperty`
      });
    },
    unverifiedProperty(pin) {
      this.propPin = pin;
      $("#cantpay")
        .addClass("fade")
        .modal("show");
      //  $("#check")
      //   .removeClass("fade")
      //   .modal("hide");
    },
    plansNavigation(data) {
      this.plantype = data;
      this.plandId = data;
      // console.log("Plan type", data);
      $("#plansSubscription")
        .removeClass("fade")
        .modal("hide");
      if (data) {
        $("#plans")
          .addClass("fade")
          .modal("show");
      }
    },
    moveToAddLandLord() {
      Api.pushPropertyDetails(this.dataDetails);
      $("#check")
        .removeClass("fade")
        .modal("hide");
      this.$router.push({
        path: `/dashboard/addlandlord`
      });
    },
    getPackage() {
      Api.getRequest(`fetchlandlordpackages`)
        .then(res => {
          // console.log("res packages", res);
          // this.packages = res.data.packages;
        })
        .catch(err => {
          console.log(err);
        });
    },
    choosePlan() {
      this.loader = true;
      // console.log("data", this.propertyId, this.planId);
      Api.postRequest(`assignpackagetoproperty`, {
        packageid: this.planId,
        propertyid: this.propertyId
      })
        .then(res => {
          this.loader = false;
          // console.log("Plan done", res);
          if (res.data.success) {
            this.fetchLandlordProperties();
            this.$store.commit("setApiSuccess", res.data.success);
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          this.loader = true;
          console.log(err);
        });
    },
    navigateTo(data, propData) {
      this.dataDetails = propData;
      if (data === 1) {
        if (propData.approved === "1") {
          // console.log("aprrovedddd", propData.approved);
          Api.pushPropertyDetails(propData);
          this.$router.push({
            path: `/dashboard/facilityissues`
          });
        } else {
          $("#cantpay")
            .addClass("fade")
            .modal("show");
        }
      } else if (data === 2) {
        this.$router.push({
          path: `/dashboard/addpm`
        });
      } else if (data === 3) {
        Api.pushPropertyDetails(propData);
        this.$router.push({
          path: `/dashboard/addtenant`
        });
      } else if (data === 4) {
        // Api.pushPropertyDetails(propData);
        $("#plansSubscription")
          .addClass("fade")
          .modal("show");
      } else if (data === 6) {
        Api.pushPropertyDetails(propData);
        this.$router.push({
          path: `/editproperty`
        });
      } else if (data === 5) {
        this.hasLandlord = propData.haslandlord;
        this.hasAccountDetails = propData.hasaccountdetails;
        // console.log("pinnnnnnnnnn", propData);
        if (
          propData.haslandlord === "NO" ||
          propData.hasaccountdetails === "NO"
        ) {
          $("#check")
            .addClass("fade")
            .modal("show");
        } else {
          Api.pushPropertyDetails(propData);
          $("#check")
            .removeClass("fade")
            .modal("hide");
          this.$router.push({
            path: `/dashboard/paymyrent`
          });
        }
      } 
      // else if (data === 7) {
      //   this.propIdToDelete = propData;
      //   // this.deteleProperty(propData.id);
      // }
      // .catch(() => {});
    },

    deleteProperty() {
      // console.log("Idddd", this.propIdToDelete);
      this.deleteLoader = true;
      Api.postRequest(`deleteproperty`, {
        propertyid: this.dataDetails.id
      })
        .then(res => {
          this.deleteLoader = false;
          if (res.data.success) {
            this.$store.commit("setApiSuccess", res.data.success);
            this.fetchLandlordProperties();
            this.$root.$refs.L.propertyCount();
            $("#deletePropertyConfirm")
              .removeClass("fade")
              .modal("hide");
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
          this.deleteLoader = false;
        });
    },
    async fetchLandlordProperties() {
      this.hideDiv = true;
      // this.loader = true;
      try {
        await Api.getRequest(`fetchpropertiesbylandlordid/${this.id}`)
          .then(res => {
            // if (res.data.error) {
            //   this.hideDiv = false;
            // }
            // console.log("Properties", res);
            if (res.data.success) {
              this.properties = res.data.properties;
            }
            this.hideDiv = false;
          })
          .catch(err => {
            // console.log(err);
            this.hideDiv = false;
            // this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getUserProfile: function() {
      Api.getRequest(`getuserbyid/${this.id}`).then(res => {
        // console.log("User profile", res);
        if (res.status == 401) {
          window.localStorage.clear();
          return window.location.href = "https://myshelta.com/login";
        }
        this.user = res.data.user;
        this.fulldata = res.data.fulldata;
        // console.log("full data", this.user);
        // this.emergency = this.fulldata.emergency_details;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$yellow: #ffb100;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.empty__div {
  padding: 1rem;
  img {
    width: 100%;
    max-width: 470.58px;
  }

  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    color: $black;
    padding-top: 1rem;
    margin-bottom: 0;
  }
  p:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    color: #000000;
  }
}

// .for_guarentee_rent {
//   margin: auto;
//   // top: -4%;
//   // left: 36%;
//   // position: absolute;
//   width: 26%;
//   // z-index: 10;
// }
.for_guarentee_rent {
  background: #2fc643;
  // width: 57%;
  width: auto;
  padding: 0 50px;
  position: relative;
  padding: 8px 0;
  p:first-child {
    // background: linear-gradient(180deg, #6886f0 0%, #0033ea 99.48%);
    // background: $yellow;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0;
    // width: 103px;
    // height: 24px;
    font-size: 13px;
    line-height: 23px;
    // content: "";
  }
  p:nth-child(2) {
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    // width: 103px;
    // height: 24px;
    font-size: 12px;
    line-height: 15px;
  }
  img {
    width: 9%;
    position: absolute;
    left: 8px;
    bottom: 14%;
  }
}

.for_guarentee_rent_approved {
  background: #3ec250;
  margin: auto;
  // width: 57%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: fit-content;
  padding: 0 40px;
  position: relative;
  p {
    // background: linear-gradient(180deg, #6886f0 0%, #0033ea 99.48%);
    // background: $yellow;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    // width: 103px;
    // height: 24px;
    font-size: 9px;
    line-height: 23px;
    // content: "";
  }
  img {
    width: 9%;
    position: absolute;
    left: 8px;
    bottom: 14%;
  }
}

.hasRentApproved {
  background: #ea0000;
  margin: auto;
  // width: 57%;
  width: fit-content;
  padding: 0 40px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: relative;
  p {
    color: $black;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 23px;
    margin-bottom: 0;
  }
  img {
    width: 7%;
    position: absolute;
    left: 8px;
    bottom: 14%;
  }
}
.dot_menu_holder {
  position: absolute;
  left: 10%;
  top: 5%;
  transform: rotate(270deg);
  span {
    width: 7px;
    height: 7px;
    color: $primary;
  }
}

.menu_text {
  color: $primary;
  font-size: 12px;
  margin-right: 3px;
  font-family: Gotham;
  font-style: normal;
}
.btn_menu::after {
  display: none !important;
}
.menu_bar {
  position: absolute;
  right: 5%;
  top: 2%;
}
.btn_menu {
  position: relative;
  width: 28px;
  height: 28px;
  background: $secondary;
  border-radius: 50%;
  border: 0.2px solid rgb(68, 68, 166);
}

.dropdown-menu-right {
  button {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    padding: 1rem 1.5rem;
    margin-top: 0 !important;
    // display: flex;
    align-items: center;
    text-align: right;
    color: #000000;
    outline: 0;
    box-shadow: none;
  }
  hr {
    // margin: 0.5rem 0;
    margin: 0;
  }
}
.dropdown-menu-right {
  padding: 0;
  button:hover {
    background: $primary;
    color: $secondary;
  }
}

.holder {
  height: 100%;
}

.properties_count {
  // text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
  div:first-of-type {
    text-align: left;
  }
  img {
    cursor: pointer;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: $black;
    span {
      color: $primary;
      font-size: 20px;
    }
  }
}

.add_prop_btn {
  button {
    background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
    border-radius: 5px;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    align-items: center;
    box-shadow: none;
    outline: 0;
  }
}

.grid_wrap {
  background: $secondary;
  // max-width: 329px;
  width: 100%;
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 20px;
  position: relative;
  .cover_img {
    width: 100%;
    // max-width: 329px;
    // max-height: 216px;
    object-fit: cover;
    // max-height: 216px;
    height: 216px;
    max-height: 100%;
    // height: 100%;
  }
}

.days_left {
  width: 67px;
  height: 67px;
  position: absolute;
  background: $secondary;
  border-radius: 50%;
  padding-top: 18px;
  margin: 7px;
  border: 1px solid #768fe9;
  h3 {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: $primary;
    margin-bottom: 0;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    text-align: center;
    color: $primary;
  }
}

.base {
  border-top: none !important;
  border: 0.2px solid #768fe9;
}

.hasRentFinancing {
  border-top: 1px solid $yellow;
  border-right: 1px solid $yellow;
  border-left: 1px solid $yellow;
}
.title_wrap {
  padding: 10px 20px 10px 20px;
  border-bottom: 0.2px solid #768fe9;
  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    display: flex;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #6c6c6c;
    display: flex;
    margin-bottom: 0;
  }
}

.price_holder {
  // display: flex;
  // justify-content: space-between;
  padding: 15px 14px 0 14px;
  align-items: center;
  border-bottom: 0.2px solid #768fe9;
}

.check_main {
  border-radius: 50%;
  background: #2fc643;
  padding: 3px 10.5px;
  top: -14px;
  position: relative;
}
.check {
  display: inline-block;
  transform: rotate(45deg);
  height: 17px;
  width: 9px;
  border-bottom: 3px solid $secondary;
  border-right: 3px solid $secondary;
}

.selected_price_holder {
  div:first-child {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    position: relative;
    p {
      font-family: Gotham;
      font-style: italic;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #6c6c6c;
      margin-bottom: 6px;
      // padding-left: 12px;
    }
  }
}
.selected_amount {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #2fc643;
  margin-bottom: 5px;
}
.real_amount {
  position: relative;
  text-align: center;
  img {
    position: absolute;
    left: 0;
  }
  // text-align: left;
  // margin-left: 5px;
}

.per_anuum {
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: $black;
    margin-bottom: 5px;
  }
  p {
    font-family: Gotham;
    font-style: italic;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #6c6c6c;
  }
}

.no_rent_value_text {
  padding-bottom: 10px;
  padding-top: 7px;
  h2 {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: $black;
  }
}

.four_button {
  background: #e6e7f3;
  border-bottom: 0.2px solid #768fe9;
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
  button:first-child {
    background: linear-gradient(181.56deg, #8c52a2 -8.75%, #43c1f2 102.5%);
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    align-items: center;
    text-align: center;
    color: $secondary;
    width: 72px;
    box-shadow: none;
    outline: 0;
    // height: 34px;
    div {
      margin-bottom: 1px;
      p {
        margin-bottom: 0;
      }
    }
  }
  button:first-child:hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  button:nth-child(2) {
    background: linear-gradient(181.56deg, #ffb100 -8.75%, #f06138 102.5%);
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    align-items: center;
    text-align: center;
    color: $secondary;
    max-width: 72px;
    box-shadow: none;
    outline: 0;
    // height: 34px;
    div {
      margin-bottom: 1px;
      p {
        margin-bottom: 0;
      }
    }
  }
  button:nth-child(3) {
    background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    align-items: center;
    text-align: center;
    color: $secondary;
    max-width: 72px;
    box-shadow: none;
    outline: 0;
    // height: 34px;
    div {
      margin-bottom: 1px;
      p {
        margin-bottom: 0;
      }
    }
  }
  button:nth-child(4) {
    background: linear-gradient(181.56deg, #2fc643 -8.75%, #16780d 102.5%);
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    align-items: center;
    text-align: center;
    color: $secondary;
    max-width: 72px;
    box-shadow: none;
    outline: 0;
    // height: 34px;
    div {
      margin-bottom: 1px;
      p {
        margin-bottom: 0;
      }
    }
  }
  button:nth-child(2):hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  button:nth-child(3):hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  button:nth-child(4):hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  button:active {
    color: #6c6c6c !important;
    background: $secondary !important;
    border: 0.2px solid #4f4e4e !important;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  button:nth-child(3) {
    background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
    border-radius: 5px;
    width: 86.31px;
    box-shadow: none;
    outline: 0;
    // height: 34px;
    img {
      // width: 100%;
    }
  }
}

.more_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    align-items: center;
    text-align: center;
    color: $primary;
    margin-bottom: 0;
    margin: 20px;
    cursor: pointer;
  }
  h6:hover {
    color: #1e306e;
  }
}

.green-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #2fc643;
  margin: auto;
  margin-right: 20px;
}
.yellow-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ffd704;
  margin: auto;
  margin-right: 20px;
  animation: blinkdot 2s infinite;
}
.red-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ea0000;
  margin: auto;
  margin-right: 20px;
  animation: blinkdot 2s infinite;
}

@keyframes blinkdot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.check_btn {
  margin: auto;
  button:first-child {
    background: $yellow;
    color: $black;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    outline: 0;
    box-shadow: none;
  }
  button:hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  button:nth-child(2) {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
}

// Modal to confirm delete starts
.confrim-delete-text {
  font-family: Gotham;
  font-size: 20px;
  font-weight: normal;
  color: $black2;
}

.confirm-delete {
  display: flex;
  justify-content: center;
  button {
    font-family: Gotham;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 20px;
    outline: 0;
    box-shadow: none;
  }
  .cancel {
    color: $primary;
    border: 1px solid $primary;
    margin-right: 10px;
  }
  .delete {
    color: $secondary;
    background: #ea0000;
  }
}
// Modal to confirm delete ends

// Modal for different plans begins
.title_modal {
  button {
    box-shadow: none;
    outline: 0;
    span {
      width: 15px;
      height: 15px;
      background: #fff;
      border-radius: 50px;
      padding: 0 6.5px;
      color: #ea0000;
      // font-family: Gotham;
      // font-style: normal;
      // font-weight: bold;
    }
  }
  background: $primary;
  padding: 1rem 2rem;
  h5 {
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }
}
.plan_body {
  padding: 1rem 2rem;
}

.plan_body-2 {
  padding: 1rem 1rem;
}

.plan_header {
  border-radius: 5px;
  padding: 16px 10px;
  margin-top: 30px;
  // height: 46px;
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 14px;
    color: $secondary;
    margin-bottom: 0;
  }
}
.standard_plan {
  background: linear-gradient(
    181.56deg,
    #ffb100 -8.75%,
    #f06138 102.5%
  ) !important;
}
.classic_plan {
  background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
}
.premium_plan {
  background: linear-gradient(181.56deg, #2fc643 -8.75%, #16780d 102.5%);
}

.plans_header_description {
  padding-top: 20px;
  color: $black;
  font-family: Gotham;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  span {
    color: $primary;
  }
}
.basic_box {
  cursor: pointer;
  font-family: Gotham;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  color: $secondary;
  padding: 35px;
  background: linear-gradient(181.56deg, #8c52a2 -8.75%, #43c1f2 102.5%);
  border-radius: 5px;
  // height: 103.56px;
  height: 100%;
  p {
    margin-bottom: 0;
  }
}

.standard_box {
  cursor: pointer;
  font-family: Gotham;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  color: $secondary;
  padding: 35px;
  background: linear-gradient(181.56deg, #ffb100 -8.75%, #f06138 102.5%);
  border-radius: 5px;
  // height: 103.56px;
  height: 100%;
  p {
    margin-bottom: 0;
  }
}

.classic_box {
  cursor: pointer;
  font-family: Gotham;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  color: $secondary;
  padding: 35px;
  background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
  border-radius: 5px;
  // height: 103.56px;
  height: 100%;
  p {
    margin-bottom: 0;
  }
}

.premium_box {
  cursor: pointer;
  font-family: Gotham;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  color: $secondary;
  padding: 35px;
  background: linear-gradient(181.56deg, #2fc643 -8.75%, #16780d 102.5%);
  border-radius: 5px;
  // height: 103.56px;
  height: 100%;
  p {
    margin-bottom: 0;
  }
}

.special_box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  font-family: Gotham;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  color: $secondary;
  padding: 35px;
  background: linear-gradient(181.56deg, #af91c5 -8.75%, #733288 102.5%);
  border-radius: 5px;
  // max-height: 218.69px;
  height: 100%;
  p {
    margin-bottom: 0;
  }
}

.plans-details_footer {
  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 22px;
    text-align: left;
    color: $black;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.plan_description {
  margin-top: 25px;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: $black;
  }
}

.benefits {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: $black;
    margin-bottom: 0;
  }
  ul {
    li {
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      text-align: left;
      color: $black;
    }
  }
}

.plan_footer_btn {
  .subscribe_text_btn {
    // background: linear-gradient(181.56deg, #FFB100 -8.75%, #F06138 102.5%);
    border-radius: 5px;
    padding: 16px 6px;
    max-width: 310px;
    width: 100%;
    margin: auto;
    cursor: pointer;
    h3 {
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 14px;
      color: $secondary;
      margin-bottom: 0;
    }
  }
  padding-top: 20px;
  border-top: 0.2px solid #e8e4e4;
  margin: 0 30px;
  .four_btn_modal {
    margin: 20px 50px 20px;
    background: none;
    border-bottom: 0;
  }
  // padding-bottom: 30px;
}

.termsOfService {
  background: #e5e5e5;
  border: 1px solid #e5e5e5;
  padding: 30px;
  h2 {
    font-family: Gotham-medium;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 14px;
    display: flex;
    color: $black;
    margin-bottom: 20px;
  }
  h6 {
    font-family: Gotham-medium;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    color: black;
    // display: flex;
    text-align: justify;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
  }
  .agree_btn {
    // text-align: right;
    margin-top: 30px;
    button:first-child {
      background: $yellow;
      margin-right: 10px;
      // border: 0.5px solid $black;
      color: $black;
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      width: 110px;
      line-height: 30px;
      box-shadow: none;
      outline: 0;
    }
    button:nth-child(2) {
      background: #2fc643;
      font-family: Gotham;
      color: $secondary;
      width: 110px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 30px;
      box-shadow: none;
      outline: 0;
      // border: 0.5px solid $secondary;
    }
  }
}

// Modal for different plans ends

@media screen and (max-width: 599px) {
  .title_modal {
    h5 {
      text-align: left;
      line-height: 27px;
    }
  }
  .grid_wrap {
    margin-bottom: 50px;
    border-radius: 0;
  }
  .days_left {
    width: 55px;
    height: 55px;
    padding-top: 10px;
    h3 {
      font-size: 13px;
    }
    h6 {
      font-size: 9px;
    }
    // bottom: -32%;
    // padding-top: 13px;
  }
  .add_prop_btn {
    button {
      font-size: 10px;
    }
  }

  .properties_count {
    h6 {
      font-size: 14px;
    }
  }

  .plan_body {
    padding: 1rem 1rem;
  }
  .plan_footer_btn {
    margin: 0 15px;
  }
  .plan_footer_btn {
    .four_btn_modal {
      margin: 20px 0px 20px;
    }
  }
  .plans-details_footer {
    h4 {
      font-size: 28px;
      line-height: 40px;
    }
  }
  .special_box {
    height: 105px;
  }
}
</style>
